<template>
  <div class="orderList container">
    <el-form inline>
      <div class="searchBox">
        <el-form-item label="注册时间">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="最近访问时间">
          <el-date-picker
            v-model="read_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="昵称">
          <el-input v-model="nick_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="消费金额">
          <el-input-number
            v-model="price_mini"
            step-strictly
            :step="0.01"
            :min="0"
            :controls="false"
            placeholder="请输入"
          ></el-input-number>
          -
          <el-input-number
            v-model="price_max"
            step-strictly
            :step="0.01"
            :min="0"
            :controls="false"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="消费次数">
          <el-input-number
            v-model="times_mini"
            step-strictly
            :step="1"
            :min="0"
            :controls="false"
            placeholder="请输入"
          ></el-input-number>
          -
          <el-input-number
            v-model="times_max"
            step-strictly
            :step="1"
            :min="0"
            :controls="false"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="平台渠道">
          <el-select v-model="platform_id" placeholder="请选择">
            <el-option
              :label="item.platform_name"
              :value="item.platform_id"
              v-for="(item, index) in platformList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </el-form>

    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
        @sort-change="sortChange"
      >
        <el-table-column label="注册时间" prop="reg_time" sortable="custom">
        </el-table-column>
        <el-table-column label="平台渠道">
          <template slot-scope="scope">
            <span>
              {{ scope.row.platform_name || "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="最近访问时间"
          prop="last_login_time"
          sortable="custom"
        ></el-table-column>
        <el-table-column label="昵称" prop="nick_name"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column
          label="消费金额（元）"
          prop="pay_money"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="消费次数"
          prop="buy_count"
          sortable="custom"
        ></el-table-column>
        <el-table-column label="单均价">
          <template slot-scope="scope">
            <span v-if="scope.row.buy_count == 0 && scope.row.pay_money">{{
              scope.row.pay_money
            }}</span>
            <span v-if="scope.row.buy_count != 0 && scope.row.pay_money">{{
              (scope.row.pay_money / scope.row.buy_count).toFixed(2)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              @click="toDetails(scope.row)"
              style="color: #4974f5; cursor: pointer"
              >查看更多</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin: 30px 0 40px 0"
    ></el-pagination>
  </div>
</template>

<script>
import commonUtil from "../../utils/commonUtil.js";
export default {
  name: "orderList",
  data() {
    return {
      status: "0",
      total: 0,
      page: 1,
      pageNumber: 10,
      create_time: [],
      read_time: [],
      nick_name: "",
      phone: "",
      price_mini: undefined,
      price_max: undefined,
      times_mini: undefined,
      times_max: undefined,
      order_by: "",
      tableData: [],
      platform_id: "",
      platformList: [],
    };
  },
  created() {
    this.getList();
    this.getplatformList();
  },
  methods: {
    getList() {
      this.tableData = [];
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
        nick_name: this.nick_name,
        phone: this.phone,
        price_mini: this.price_mini || 0,
        price_max: this.price_max || "",
        times_mini: this.times_mini || 0,
        times_max: this.times_max || "",
        order_by: this.order_by,
        platform_id: this.platform_id,
      };
      if (this.create_time[0]) {
        data.create_time_start = this.dayjs(this.create_time[0]).format(
          "YYYY-MM-DD"
        );
        data.create_time_end = this.dayjs(this.create_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.read_time[0]) {
        data.read_time_start = this.dayjs(this.read_time[0]).format(
          "YYYY-MM-DD"
        );
        data.read_time_end = this.dayjs(this.read_time[1]).format("YYYY-MM-DD");
      }
      console.log("data", data);
      this.axios.post("/store/Shopuser/getShopUsers", data).then((res) => {
        this.total = res.data.total;
        let list = res.data.list;
        list.forEach((ele) => {
          if (ele.reg_time) {
            ele.reg_time = this.dayjs
              .unix(ele.reg_time)
              .format("YYYY-MM-DD HH:mm");
          }
          if (ele.last_login_time) {
            ele.last_login_time = this.dayjs
              .unix(ele.last_login_time)
              .format("YYYY-MM-DD HH:mm");
          }
        });
        this.tableData = list;
      });
    },
    getplatformList() {
      this.axios.post("/store/shopuser/getChannelList").then((res) => {
        this.platformList = res.data;
      });
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    reset() {
      commonUtil.lostFocus(event);
      this.create_time = [];
      this.read_time = [];
      this.nick_name = "";
      this.phone = "";
      this.price_mini = undefined;
      this.price_max = undefined;
      this.times_mini = undefined;
      this.times_max = undefined;
      this.order_by = "";
      this.page = 1;
      this.platform_id = "";
      this.platformList = [];
      this.getList();
      this.getplatformList();
    },
    search() {
      commonUtil.lostFocus(event);
      this.page = 1;
      this.getList();
    },
    sortChange(e) {
      let prop = e.prop;
      let order = e.order;
      if (prop == "last_login_time" && order == "descending") {
        this.order_by = 1;
      } else if (prop == "last_login_time" && order == "ascending") {
        this.order_by = 2;
      } else if (prop == "pay_money" && order == "ascending") {
        this.order_by = 3;
      } else if (prop == "pay_money" && order == "descending") {
        this.order_by = 4;
      } else if (prop == "buy_count" && order == "ascending") {
        this.order_by = 5;
      } else if (prop == "buy_count" && order == "descending") {
        this.order_by = 6;
      } else if (prop == "reg_time" && order == "ascending") {
        this.order_by = 7;
      } else if (prop == "reg_time" && order == "descending") {
        this.order_by = 8;
      }
      this.getList();
    },
    // 查看更多
    toDetails(val) {
      this.$router.push({
        path: "./ListDetails",
        query: {
          user_id: val.user_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.orderList {
  .el-range-editor {
    width: 250px;
  }
}
</style>
